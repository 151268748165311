exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-beauty-gadgets-tsx": () => import("./../../../src/pages/beauty-gadgets.tsx" /* webpackChunkName: "component---src-pages-beauty-gadgets-tsx" */),
  "component---src-pages-catalogue-index-tsx": () => import("./../../../src/pages/catalogue/index.tsx" /* webpackChunkName: "component---src-pages-catalogue-index-tsx" */),
  "component---src-pages-catalogue-range-json-name-tsx": () => import("./../../../src/pages/catalogue/{rangeJson.name}.tsx" /* webpackChunkName: "component---src-pages-catalogue-range-json-name-tsx" */),
  "component---src-pages-cms-software-tsx": () => import("./../../../src/pages/cms-software.tsx" /* webpackChunkName: "component---src-pages-cms-software-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-signage-templates-tsx": () => import("./../../../src/pages/signage-templates.tsx" /* webpackChunkName: "component---src-pages-signage-templates-tsx" */)
}

